.App {
  background-color: #f9f9f9;
  min-height: 100vh;
}
/* @media (min-width: 300px) and (max-width: 800px) {
  .App {
    border: 2px solid purple;
  }
} */
.naira {
  font-weight: normal;
}
.naira.bold {
  font-weight: bold;
}
