.auth-page {
  height: calc(100vh - 80px);
  padding: 20px 80px;
  margin-top: 80px;
  background-color: #f9f9f9;
  background-image: url(../assets/images/illustrations/register.svg);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  position: relative;
}
/* 
.auth-form {
  width: 55%;
  margin-left: 55%;
  height: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: hidden;
  padding: 20px 40px;
 
} */

.auth-form {
  width: 35%;
  margin-left: 55%;
  height: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: hidden;
  padding: 20px 40px;
  -ms-overflow-style: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}
/* .auth-form form input {
  border: 2px solid red;
  width: 80%;
} */

.auth-form::-webkit-scrollbar {
  width: 6px;
  background-color: #ccf5ff;
  /* border: 2px solid red; */
  display: none;
}
.auth-form::-webkit-moz-scrollbar {
  width: 6px;
  background-color: #ccf5ff;
  /* border: 2px solid red; */
  display: none;
}
.auth-form::-webkit-scrollbar-thumb {
  background-color: #00ccff;
  border-radius: 6px;
  /* border: 2px solid red; */
}
.invisible-scrollbar::-webkit-moz-scrollbar {
  display: none;
}

.auth-form form {
  width: 100%;
}

.auth-form h1 {
  color: #600080;
  margin: 0 0 20px;
  padding: 5px 10px;
  border-bottom: 2px solid #f2ccff;
}

.auth-page .auth-error {
  padding: 10px;
  margin: -15px 0 20px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 3px;
  font-weight: 300;
  color: #ff0066;
  background-color: #ffe6f0;
  border: 1px solid #ff0066;
}

.auth-page .auth-error .lnk {
  font-weight: 600;
  text-decoration: none;
}

.auth-page .auth-error .lnk:hover {
  text-decoration: underline;
}

.auth-page .submit-box {
  margin: 20px 0 40px;
}

.auth-page .submit-box button {
  background-color: #00ccff;
  padding: 10px 25px;
  display: inline-block;
  min-width: 150px;
  border: none;
  border-radius: 3px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 1px;
  outline: 0;
  font-weight: 600;
  transition: all 0.2s;
  cursor: pointer;
}

.auth-page .submit-box button:disabled {
  background-color: #b3f0ff;
}

.auth-page .submit-box .submit {
  background-color: #730099;
}

.auth-page .submit-box .submit:hover {
  background-color: #600080;
  transition: all 0.2s;
}

.auth-page .submit-box .submit:active {
  background-color: #4d0066;
}

.auth-page .submit-box .submit:disabled {
  background-color: #ecb3ff;
}

.auth-page .submit-box .forgot {
  text-decoration: none;
  color: #ecb3ff;
  font-size: 14px;
  display: inline-block;
  letter-spacing: 1px;
  padding: 11px 0;
  margin-left: 10px;
  transition: all 0.2s;
}

.auth-page .submit-box .forgot:hover {
  color: #600080;
}

.auth-page .submit-box .text {
  margin-top: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333333;
}

.auth-page .submit-box .text a {
  text-decoration: none;
  color: #9900cc;
}

.auth-page .submit-box .text a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1025px) {
  .auth-page {
    padding: 20px;
  }

  .auth-form {
    width: 38%;
    margin-left: 50%;
    padding: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .auth-page {
    min-height: 700px;
  }

  .auth-form {
    width: 40%;
  }
}

@media only screen and (max-width: 769px) {
  .auth-form {
    width: 90%;
    margin-left: 2%;
    padding: 20px;
  }
  form {
    width: 100%;
  }
}

@media only screen and (max-width: 412px) {
  .auth-page {
    min-height: calc(100vh - 80px);
    padding: 10px;
    background-size: 100% auto;
  }

  .auth-form {
    width: 100%;
    margin-left: 0;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  form {
    width: 100%;
  }
}
