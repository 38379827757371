.page-loading {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.page-loading .slider {
    background-color: #9900CC;
    background-image: linear-gradient(90deg, #00A3CC, #9900CC);
    box-shadow: 0px 2px 0px rgba(0,0,0,0.1);
    height: 2px;
    margin-top: 2px;
    border-radius: 1px;
    width: 90%;
    animation: sloader .5s;
}

@keyframes sloader {
    from {width: 0;}
    to {width: 90%;}
}

.page-loading .ripple {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    width: 80px;
    height: 80px;
}

.page-loading .ripple div {
    position: absolute;
    border: 4px solid #00A3CC;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.page-loading .ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
}
  