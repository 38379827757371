.profile-box {
  padding: 15px;
}

.profile-box {
  padding: 15px;
  float: left;
  width: 100%;
}

.profile-box.w-50 {
  width: 40%;
}

.profile {
  min-height: calc(100vh - 180px);
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  transition: all 0.3s;
}

.profile-intro {
  position: relative;
  padding: 20px 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #f9f9f9;
  margin: -20px 0 20px;
  letter-spacing: 1px;
  font-weight: 300;
}

.profile-flag {
  position: absolute;
  top: 0;
  right: 20px;
  width: auto;
  padding: 8px 30px;
  letter-spacing: 2px;
  font-weight: 800;
  color: #00ccff;
  background-color: rgba(255, 255, 255, 1);
}
.profile-flag.red {
  color: #ff0066;
}
.profile-flag.green {
  color: #00cc00;
}

.profile-intro .desc {
  width: 33.333333%;
  margin: 10px 0;
}

.profile-intro .desc.big,
.profile-intro .desc.wide {
  width: 100%;
}

.profile-intro .label {
  color: #999999;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 2px;
}

.profile-intro .value {
  color: #333333;
  font-weight: 600;
  font-size: 22px;
}

.profile-intro .value.editable {
  color: #730099;
}

.profile-intro .big .value {
  font-size: 36px;
  color: #730099;
}

.profile-links {
  margin: 20px 0 0;
  text-align: right;
}

.profile-links .profile-link {
  text-decoration: none;
  display: inline-block;
  margin: 0px 5px;
  padding: 7px 20px;
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  color: #730099;
  transition: all 0.2s;
  cursor: pointer;
}
.profile-links .profile-link.b {
  padding: 10px 30px;
  font-size: 14px;
}

.profile-links .profile-link:hover {
  background-color: #f2f2f2;
}

.profile-links .profile-link.purple {
  background-color: #730099;
  color: #fff;
}
.profile-links .profile-link.purple:hover {
  background-color: #4d0066;
  color: #fff;
}
@media only screen and (max-width: 1025px) {
  .profile-intro .big .value {
    font-size: 34px;
  }
}

@media only screen and (max-width: 992px) {
  .profile-intro .big .value {
    font-size: 30px;
  }
}

@media only screen and (max-width: 769px) {
  .profile-intro {
    padding: 20px;
  }

  .profile-intro .desc.wide-mob {
    width: 100%;
  }

  .profile-intro .desc {
    width: 50%;
  }

  .profile-intro .big .value {
    font-size: 24px;
  }

  .profile-intro .value {
    font-size: 16px;
  }
}

@media only screen and (max-width: 412px) {
  .profile {
    padding: 20px 10px 80px;
  }

  .profile-intro {
    padding: 10px;
  }

  .profile-intro .desc.big {
    width: 100%;
  }

  .profile-intro .label {
    font-size: 12px;
  }

  .profile-intro .big .value {
    font-size: 20px;
  }

  .profile-intro .value {
    font-size: 14px;
  }
}

.profile-section {
  padding: 5px 20px;
  transition: all 0.3s;
}

.profile-section .title {
  color: #730099;
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  padding: 5px 10px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
  position: relative;
  transition: all 0.2s;
}

.profile-text {
  padding: 10px 20px 20px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
  letter-spacing: 1px;
  line-height: 1.5;
  font-weight: 300;
  color: #333333;
}

.profile-text .desc {
  margin: 10px 0;
  line-height: 1.3;
  width: 33.333333%;
}

.profile-text .label {
  color: #999999;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px;
}

.profile-text .value {
  color: #333333;
  font-weight: 600;
  font-size: 22px;
}

.profile-text .value.editable {
  color: #730099;
}

.profile-assessment {
  position: relative;
  background-color: #f9e6ff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-assessment .question {
  width: 65%;
  height: 100%;
  background-color: #ffffff;
  padding: 10px;
}

.profile-assessment .answer {
  width: 35%;
  font-size: 20px;
  line-height: 1.2;
  padding: 10px 15px;
  font-weight: 600;
  color: #000000;
}

.profile-assessment .answer a {
  text-decoration: none;
  color: #999999;
  font-weight: 400;
  font-size: 16px;
  display: block;
  transition: all 0.2s;
}

.profile-assessment .answer a .la {
  font-size: 20px;
  color: #730099;
}

.profile-assessment .answer a:hover {
  color: #730099;
}
.suspense_btn {
  letter-spacing: 1px;
  font-weight: 500;
  outline: none;
  border: none;
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
}
button.suspense_btn.grey {
  background: grey;
  color: #333;
}
.suspense_btn.purple {
  background: #730099;
  color: #fff;
}

@media only screen and (max-width: 769px) {
  .profile-section {
    padding: 5px 0;
  }

  .profile-section .title {
    font-size: 20px;
  }

  .profile-text {
    padding: 10px 10px 20px;
  }

  .profile-text .desc {
    width: 100%;
  }

  .profile-text .label {
    font-size: 12px;
  }

  .profile-text .value {
    font-size: 18px;
  }

  .profile-assessment .question {
    font-size: 14px;
    width: 70%;
  }

  .profile-assessment .answer {
    font-size: 14px;
    width: 30%;
    padding: 10px;
  }

  .profile-assessment .answer a {
    font-size: 14px;
  }

  .profile-assessment .answer a .la {
    font-size: 18px;
  }
}

@media only screen and (max-width: 412px) {
  .profile-assessment .question {
    width: 100%;
  }

  .profile-assessment .answer {
    font-size: 14px;
    width: 100%;
    padding: 10px;
  }

  .profile-assessment .answer a {
    font-size: 14px;
  }

  .profile-assessment .answer a .la {
    font-size: 18px;
  }
}
